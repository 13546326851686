import PropTypes from "prop-types";
import React from "react";

function EmptyLayout(props) {
    return (
        <div className="overflow-auto">
            {props.children}
        </div>
    );
}

EmptyLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default EmptyLayout;
