import React from "react";

import EmptyLayout from "../components/EmptyLayout";

function TestPage() {

    return (
        <EmptyLayout>
            {/*<div className="fixed flex border-2 w-full justify-center bg-white">*/}
            {/*    <div className="flex flex-col lg:flex-row w-screen max-w-6xl justify-between">*/}
            {/*        <div className="flex justify-between">*/}
            {/*            <div className="border-2 ">Hello</div>*/}
            {/*            <div className="lg:hidden">Menu</div>*/}
            {/*        </div>*/}
            {/*        <div className="flex-col items-start lg:flex lg:flex-row">*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*            <div className="border-2">1234567890</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="mt-10 flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="flex border-2">*/}
            {/*    <p className="border-2 text-4xl">*/}
            {/*        This is my content paosjd gpajsdpgjpaosjdg pojasdpogjpaosjd gpoja spdjg asdg aosidjg oaijsdgoiajsgao*/}
            {/*        aosdigj aoisjdgoijasdoigj aoisdjg oiasdoigjh oaidhg oiha sdoighaoidshg oahd iogh aosdihg oiahsd goih*/}
            {/*        aosjgoji oij sdoijg oiajsdoijaoisdjg oijasd ogihaoishdg oihasdoigh oiahsdk oaisdhg ahsd giuahsd lifu*/}
            {/*        oasigh oahsd ohiasd ;ogh aoidshg oiahsd goihads ogh aoidshg ohasd oghaiu dgoij aoiwjg oahwe giohweg*/}
            {/*    </p>*/}
            {/*</div>*/}
        </EmptyLayout>
    )
}

export default TestPage;